import { ErrorMessage as ErrMsg } from "formik";
import React from "react";
interface Props {
  name: string;
  className?: string;
}

const ErrorMessage = ({ name, className }: Props) => {
  return (
    <ErrMsg
      name={name}
      render={(msg) => (
        <div className={className || "pt-1 text-red-500"}>{msg}</div>
      )}
    />
  );
};

export default ErrorMessage;
