import React from "react";

const CheckMarkIcon = ({ className = "h-6 w-6" }) => {
  return (
    <svg
      viewBox="0 0 18 13"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 1.14473L6 11.9341L1 7.02984"
        stroke="#828891"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckMarkIcon;
