import axios from "axios";

export interface ApiResponse<T> {
  success: boolean;
  data: T;
}

const Api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || "/api",
});

Api.interceptors.request.use(function (config) {
  if (localStorage._token) {
    config.headers["Authorization"] = `Bearer ${localStorage._token}`;
  }
  config.headers["lang"] = localStorage.lng || "en";

  return config;
});

export const fetcher = (url: any) => Api.get(url).then(({ data }) => data);

export const parseErrorValidation = (errors: any) => {
  const keys = Object.keys(errors);
  const out = {};
  for (const key of keys) {
    out[key] = errors[key].join(" ");
  }
  return out;
};

export const getBaseURL = (pathname: any) => {
  try {
    const url = new URL(
      process.env.REACT_APP_BASE_URL || "http://localhost:3000/api"
    );
    url.pathname = pathname;
    return url.toString();
  } catch (e) {
    return pathname;
  }
};

export default Api;
