import clsx from "clsx";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import InputField from "../Components/InputField";
import CloseIcon from "../icons/CloseIcon";
import { RootState } from "../stores";
import { setLogin } from "../stores/features/user";
import Api, { parseErrorValidation } from "../utils/api";
import Button from "./Button";
import ErrorMessage from "./ErrorMessage";
import FormLoading from "./FormLoading";
import Modal from "./Modal";

const basicValidation = Yup.object().shape({
  name: Yup.string().required(),
  email: Yup.string().email().required(),
  age: Yup.number().required().min(13),
  occupation: Yup.string().required(),
  company: Yup.string().required(),
  phone: Yup.string()
    .matches(/\d{9,13}/, "Invalid phone number format")
    .required(),
  address: Yup.string().required(),
});

const passwordValidation = Yup.object().shape({
  current_password: Yup.string().required().min(3),
  new_password: Yup.string().required().min(8),
  confirm_password: Yup.string()
    .required()
    .oneOf([Yup.ref("new_password"), null], "Passwords must match"),
});

const passwordInitialValues = {
  current_password: "",
  new_password: "",
  confirm_password: "",
};

const EditProfile = (props) => {
  const user = useSelector<RootState>((state) => state.user.user);
  const dispatch = useDispatch();
  const { setIsOpen } = props;
  const { t } = useTranslation();
  const [section, setSection] = useState(0);
  const tabs = ["Basic Info", "Change Password"];

  const onSubmitBasic = async (values, { setErrors }) => {
    try {
      const { data } = await Api.post("/me", values);
      dispatch(setLogin({ user: data.data }));
      toast.success(t("Profile changed sucessfully!"));
      props.setIsOpen(false);
    } catch (e) {
      if (e.response?.data?.errors) {
        setErrors(parseErrorValidation(e.response?.data?.errors));
      } else {
        toast.error(
          e.response?.data?.message || "Something went wrong!, please try again"
        );
      }
    }
  };
  const onSubmitPassword = async (values, { setErrors }) => {
    try {
      await Api.post("/me/password", values);
      toast.success(t("Password changed sucessfully!"));
      props.setIsOpen(false);
    } catch (e) {
      if (e.response?.data?.errors) {
        setErrors(parseErrorValidation(e.response?.data?.errors));
      } else {
        toast.error(
          e.response?.data?.message || "Something went wrong!, please try again"
        );
      }
    }
  };

  return (
    <Modal
      {...(props as any)}
      className="bg-white p-4 rounded-lg font-sans max-w-lg"
    >
      <div className="flex border-b border-gray-300 pb-2">
        <h2 className="text-2xl flex-1">{t("Edit Profile")}</h2>
        <button
          className="hover:opacity-50 p-1"
          onClick={() => setIsOpen(false)}
        >
          <CloseIcon />
        </button>
      </div>
      <div className="p-2 -mb-4">
        <div className="-mx-2">
          <ul className="flex mb-6">
            {tabs.map((label, index) => (
              <li key={index} className="flex-1">
                <button
                  onClick={() => setSection(index)}
                  className={clsx(
                    "w-full p-2 border-b-4 font-semibold text-gray-500",
                    "transition-all duration-150 hover:bg-gray-100",
                    {
                      "border-red-500 text-gray-800": index === section,
                    }
                  )}
                >
                  {label}
                </button>
              </li>
            ))}
          </ul>
        </div>
        {section === 0 && (
          <Formik
            initialValues={user}
            onSubmit={onSubmitBasic}
            validationSchema={basicValidation}
          >
            {({ isSubmitting }) => (
              <Form>
                <FormLoading isLoading={isSubmitting}>
                  <div className="flex flex-wrap -mx-4">
                    <div className="p-2 w-full">
                      <label className="block mb-2 font-bold">
                        {t("Full Name")}
                      </label>
                      <InputField
                        className="form-input w-full"
                        name="name"
                        type="text"
                        placeholder={t("Full Name")}
                      />
                      <ErrorMessage name="name" />
                    </div>

                    <div className="p-2 w-full">
                      <label className="block mb-2 font-bold">
                        {t("Email")}
                      </label>
                      <InputField
                        className="form-input w-full"
                        name="email"
                        type="email"
                        placeholder={t("Email")}
                      />
                      <ErrorMessage name="email" />
                    </div>

                    <div className="p-2 w-full">
                      <label className="block mb-2 font-bold">{t("Age")}</label>
                      <div className="w-28">
                        <InputField
                          className="form-input"
                          name="age"
                          type="number"
                          placeholder={t("Age")}
                        />
                      </div>

                      <ErrorMessage name="age" />
                    </div>

                    <div className="p-2 w-full md:w-6/12">
                      <label className="block mb-2 font-bold">
                        {t("Occupation")}
                      </label>
                      <InputField
                        className="form-input w-full"
                        name="occupation"
                        type="text"
                        placeholder={t("Occupation")}
                      />
                      <ErrorMessage name="occupation" />
                    </div>

                    <div className="p-2 w-full md:w-6/12">
                      <label className="block mb-2 font-bold">
                        {t("Company Name")}
                      </label>
                      <InputField
                        className="form-input w-full"
                        name="company"
                        type="text"
                        placeholder={t("Company Name")}
                      />
                      <ErrorMessage name="company" />
                    </div>

                    <div className="p-2 w-full">
                      <label className="block mb-2 font-bold">
                        {t("Phone Number")}
                      </label>
                      <div className="flex">
                        <div className="py-2 pl-3 pr-4 bg-gray-200 -mr-1 border border-gray-300 rounded-l-md">
                          +62
                        </div>
                        <InputField
                          className="form-input w-full"
                          name="phone"
                          type="text"
                          placeholder="000 000 000"
                        />
                      </div>

                      <ErrorMessage name="phone" />
                    </div>

                    <div className="p-2 w-full">
                      <label className="block mb-2 font-bold">
                        {t("Full Address")}
                      </label>
                      <InputField
                        className="form-input w-full"
                        name="address"
                        component="textarea"
                        placeholder={t("Full Address")}
                      />
                      <ErrorMessage name="address" />
                    </div>
                    <div className="p-2 w-full flex justify-end">
                      <Button
                        type="submit"
                        color="primary"
                        className="font-bold"
                      >
                        {t("Save Changes")}
                      </Button>
                    </div>
                  </div>
                </FormLoading>
              </Form>
            )}
          </Formik>
        )}

        {section === 1 && (
          <Formik
            initialValues={passwordInitialValues}
            onSubmit={onSubmitPassword}
            validationSchema={passwordValidation}
          >
            {({ isSubmitting }) => (
              <Form>
                <FormLoading isLoading={isSubmitting}>
                  <div className="flex flex-wrap -mx-4">
                    <div className="p-2 w-full">
                      <label className="block mb-2 font-bold">
                        {t("Old Password")}
                      </label>
                      <InputField
                        className="form-input w-full"
                        name="current_password"
                        type="password"
                        placeholder={t("Old Password")}
                      />
                      <ErrorMessage name="current_password" />
                    </div>

                    <div className="p-2 w-full">
                      <label className="block mb-2 font-bold">
                        {t("New Password")}
                      </label>
                      <InputField
                        className="form-input w-full"
                        name="new_password"
                        type="password"
                        placeholder={t("New Password")}
                      />
                      <ErrorMessage name="new_password" />
                    </div>

                    <div className="p-2 w-full">
                      <label className="block mb-2 font-bold">
                        {t("Repeat Password")}
                      </label>
                      <InputField
                        className="form-input w-full"
                        name="confirm_password"
                        type="password"
                        placeholder={t("Repeat Password")}
                      />
                      <ErrorMessage name="confirm_password" />
                    </div>

                    <div className="p-2 w-full flex justify-end">
                      <Button
                        type="submit"
                        color="primary"
                        className="font-bold"
                      >
                        {t("Save Changes")}
                      </Button>
                    </div>
                  </div>
                </FormLoading>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </Modal>
  );
};

export default EditProfile;
