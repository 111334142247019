import clsx from "clsx";
import React from "react";

const IDIcon = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={clsx("w-5", className)}
      viewBox="0 85.333 512 341.333"
    >
      <path fill="#FFF" d="M0 85.337h512v341.326H0z" />
      <path fill="#A2001D" d="M0 85.337h512V256H0z" />
    </svg>
  );
};

export default IDIcon;
