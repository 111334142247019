import i18n from "i18next";
import lgDetect from "i18next-browser-languagedetector";
import Backend from "i18next-xhr-backend";
import qs from "querystring";
import React from "react";
import { initReactI18next, useTranslation } from "react-i18next";
import IDIcon from "./icons/IDIcon";
import USicon from "./icons/USicon";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)

export const languages = [
  { id: "en", label: "English US", icon: <USicon className="w-5" /> },
  { id: "id", label: "Indonesia", icon: <IDIcon className="w-5" /> },
];

i18n.on("languageChanged", function (lng) {
  localStorage.setItem("lng", lng);
});

//@ts-ignore
i18n
  .use(Backend)
  .use(lgDetect)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: process.env.NODE_ENV === "production" ? false : true,
    fallbackLng: "en",
    whitelist: ["en", "id"],
    // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    supportedLngs: ["en", "id"],
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    ns: ["translation"],
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`, //Path to the translation files
      addPath: `${process.env.PUBLIC_URL}/locales/add/{{lng}}/{{ns}}`,
    },
    detection: {
      order: ["localStorage"],
      lookupLocalStorage: "lng",
      checkWhitelist: true,
    },
    saveMissing: true,
    saveMissingTo: "all",
  });

export const useWithLang = () => {
  const { i18n } = useTranslation();

  return {
    f: (url) => {
      const [path, qstring] = url.split("?");
      if (qstring) {
        const query = qs.parse(qstring);
        query.lang = i18n.language;
        return path + "?" + qs.stringify(query);
      }
      return url + "?lang=" + i18n.language;
    },
  };
};

export default i18n;
