import { Link } from "@reach/router";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import CheckMarkIcon from "../icons/CheckMarkIcon";
import { Plan } from "../utils/types";
import Button from "./Button";

export const EventPlanItem = ({
  item,
  className = "",
}: {
  item: Plan;
  className?: string;
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={clsx(
        "flex flex-col border border-gray-500 p-2 rounded-lg",
        className
      )}
    >
      <header className="event-header bg-gray-100 p-2 text-center rounded-lg">
        <h1 className="text-xl font-sans font-semibold">
          {item.name || "Unnamed"}
        </h1>
      </header>
      <p className="text-center my-4 text-3xl text-red-600 font-sans font-bold">
        {item.price === 0 ? (
          <>Free</>
        ) : (
          <>Rp. {new Intl.NumberFormat("id-ID").format(item.price || 0)}</>
        )}
      </p>

      <ul className="border-t border-gray-300">
        {item.features.map((label, index) => (
          <li
            key={index}
            className="border-b flex border-gray-300 py-4 px-6 text-gray-500"
          >
            <span className="mr-3">
              <CheckMarkIcon className="w-4 mt-2" />
            </span>
            <span className="flex-1">{label}</span>
          </li>
        ))}
      </ul>

      <footer className="event-footer flex justify-center py-4">
        <Link to={item.link}>
          <Button color="primary" className="font-sans">
            {t("REGISTER")}
          </Button>
        </Link>
      </footer>
    </div>
  );
};

const EventPlansSection = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-white py-10">
      <div className="container mx-auto">
        <div className="flex items-center mb-8">
          <div className="mr-3">
            <img
              src="/images/pandi.png"
              className="w-14 md:w-auto"
              alt="pandi-light"
            />
          </div>
          <h2 className="text-3xl font-semibold font-sans ">
            {t("EVENT PRICE LIST")}
            <span className="font-sans font-normal italic">
              / {t("Perfect Price for Event")}
            </span>
          </h2>
        </div>
        <div className="flex flex-col md:flex-row -mx-2">
          {[...new Array(3)].map((_, index) => {
            return (
              <EventPlanItem
                key={index}
                item={{
                  name: "The title",
                  description: "Lorem",
                  photo: "",
                  price: 0,
                  category_id: 1,
                  link: "/register?cat=1",
                  features: ["features 1", "features 2", "features 3"],
                }}
                className="md:w-4/12 m-2"
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default EventPlansSection;
