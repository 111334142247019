import React from "react";

const MicIcon = ({ className = "h-6 w-6" }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 13 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.50001 1C5.87485 1 5.27531 1.25662 4.83326 1.7134C4.39121 2.17019 4.14286 2.78972 4.14286 3.43571V9.93095C4.14286 10.5769 4.39121 11.1965 4.83326 11.6533C5.27531 12.11 5.87485 12.3667 6.50001 12.3667C7.12516 12.3667 7.72471 12.11 8.16676 11.6533C8.60881 11.1965 8.85715 10.5769 8.85715 9.93095V3.43571C8.85715 2.78972 8.60881 2.17019 8.16676 1.7134C7.72471 1.25662 7.12516 1 6.50001 1V1Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 8.30713V9.93094C12 11.4383 11.4205 12.8838 10.3891 13.9497C9.35764 15.0155 7.95869 15.6143 6.5 15.6143C5.04131 15.6143 3.64236 15.0155 2.61091 13.9497C1.57946 12.8838 1 11.4383 1 9.93094V8.30713"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 15.6143V18.8619"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.35714 19.053H9.64285"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MicIcon;
