import { Link } from "@reach/router";
import clsx from "clsx";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "react-use";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import useSWR from "swr";
import { useWithLang } from "../i18n";
import CalendarIcon from "../icons/CalendarIcon";
import ChevronLeft from "../icons/ChevronLeft";
import ChevronRight from "../icons/ChevronRight";
import MarkerIcon from "../icons/MarkerIcon";
import MicIcon from "../icons/MicIcon";
import { ApiResponse } from "../utils/api";
import Button from "./Button";
import { useSelector } from "react-redux";
import { RootState } from "../stores";

SwiperCore.use([Navigation, Autoplay]);

export interface SliderItem {
  id: number;
  title: string;
  subtitle: string;
  description: string;
  date: string;
  link: string;
  video_link: string;
}

interface SliderResponse {
  sliders: SliderItem[];
  sliders_data: {
    speakers_count: number;
    event_date?: {
      name: string;
      value: string;
    };
    event_place: string;
  };
}

interface Props {
  compRef: any;
}

const BigSlides = ({ compRef }: Props) => {
  const { t } = useTranslation();
  const { f } = useWithLang();
  const { data } = useSWR<ApiResponse<SliderResponse>>(f("/public/sliders"), {
    initialData: null,
  });
  const { isLogin } = useSelector((state: RootState) => state.user);
  const url_register = isLogin ? "/register-event" : "https://s.id/form-ibd";
  const { height } = useWindowSize();

  return (
    <div
      style={{ minHeight: Math.max(height - 100, 600) }}
      className="bg-gray-400 relative"
      id="big-sliders"
      ref={compRef}
    >
      <div
        className="absolute w-full h-full bg-center bg-cover"
        style={{
          backgroundImage: `url(/images/ibd/background_ibd.jpg)`,
        }}
      >
        {data !== null && (
          <>
            <div className="absolute w-full h-full bg-black bg-opacity-10">
              <div className="container mx-auto flex items-center justify-between h-full">
                <div className="relative z-20">
                  <button
                    className={clsx(
                      "text-white slide-btn-left border border-white rounded-lg",
                      "transform duration-200",
                      "hover:bg-red-500 hover:border-red-500 hover:-translate-x-2",
                      { hidden: data.data.sliders.length < 2 }
                    )}
                  >
                    <ChevronLeft className="w-10 h-10" />
                  </button>
                </div>
                <div className="relative z-20">
                  <button
                    className={clsx(
                      "text-white slide-btn-right border border-white rounded-lg",
                      "transform duration-200",
                      "hover:bg-red-500 hover:border-red-500 hover:translate-x-2",
                      { hidden: data.data.sliders.length < 2 }
                    )}
                  >
                    <ChevronRight className=" w-10 h-10" />
                  </button>
                </div>
              </div>
            </div>
            <Swiper
              className="h-full"
              navigation={{
                nextEl: ".slide-btn-right",
                prevEl: ".slide-btn-left",
              }}
              loop={data.data.sliders.length > 1}
              autoplay={data.data.sliders.length > 1}
              speed={1000}
            >
              {data.data.sliders.map((item, index) => (
                <SwiperSlide key={index} className="h-full pt-28">
                  <div className="container flex flex-col items-center justify-center h-full mx-auto">
                    <time className="md:text-3xl font-sans mb-3">
                      {item.date}
                    </time>
                    <h1 className="text-3xl md:text-6xl font-bold font-sans text-center">
                      {item.title}
                    </h1>
                    <div className="countdown flex flex-wrap text-center md:text-2xl font-sans mt-6 px-8 md:px-0">
                      <div>{item.description}</div>
                    </div>
                    <div className="mt-6 flex">
                      {isLogin ? (
                        <Link to={url_register}>
                          {" "}
                          <Button
                            className="mx-2 mb-4 md:text-2xl font-sans"
                            color="primary"
                          >
                            {t("REGISTER")}
                          </Button>
                        </Link>
                      ) : (
                        <a href={url_register}>
                          <Button
                            className="mx-2 mb-4 md:text-2xl font-sans"
                            color="primary"
                          >
                            {t("REGISTER")}
                          </Button>
                        </a>
                      )}

                      {item.video_link && (
                        <a
                          href={item.video_link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Button
                            className="mx-2 mb-4 md:text-2xl font-sans"
                            // color="white"
                            outline
                          >
                            {t("WATCH VIDEO")}
                          </Button>
                        </a>
                      )}
                      <Link to="/about">
                        <Button
                          className="mx-2 mb-4 md:text-2xl font-sans"
                          color="primary"
                        >
                          TENTANG IBD
                        </Button>
                      </Link>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </>
        )}
      </div>
      {data !== null && (
        <div className="absolute bottom-0 w-full z-10 hidden md:block">
          <div className="container mx-auto">
            <div className="flex bg-gray-800 text-white rounded-t-lg font-semibold">
              <div className="px-6 py-3 w-4/12 flex items-center">
                <div>
                  <CalendarIcon />
                </div>
                <div className="w-full pl-3 pr-6">
                  <h3 className="text-red-600">{t("Date")}</h3>
                  <p className="overflow-hidden whitespace-nowrap overflow-ellipsis">
                    {data.data.sliders_data.event_date
                      ? data.data.sliders_data.event_date.value
                      : moment().format("DD MMMM YYYY")}
                  </p>
                </div>
              </div>

              <div className="px-6 py-3 w-4/12 flex items-center">
                <div>
                  <MarkerIcon />
                </div>
                <div className="w-full pl-3 pr-6">
                  <h3 className="text-red-600">{t("Location")}</h3>
                  <p className="overflow-hidden whitespace-nowrap overflow-ellipsis">
                    {data.data.sliders_data.event_place
                      ? data.data.sliders_data.event_place
                      : "Jakarta"}
                  </p>
                </div>
              </div>

              <div className="px-6 py-3 w-4/12 flex items-center">
                <div>
                  <MicIcon />
                </div>
                <div className="w-full pl-3 pr-6">
                  <h3 className="text-red-600">{t("Speakers")}</h3>
                  <p className="overflow-hidden whitespace-nowrap overflow-ellipsis">
                    {data.data.sliders_data.speakers_count}{" "}
                    {t("Profesional Speakers")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BigSlides;
