import { RouteComponentProps, useNavigate } from "@reach/router";
import { useTranslation } from "react-i18next";
import Helmet from "react-helmet";
import Layout from "../Components/Layout";
import React, { useEffect } from "react";
import EventRegistration from "../Components/EventRegistration";
import { useSelector } from "react-redux";
import { RootState } from "../stores";

interface Props extends RouteComponentProps {}

const RegisterEventPage = (props: Props) => {
  const { t } = useTranslation();
  const { isLogin } = useSelector((s: RootState) => s.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLogin) {
      navigate("/login");
    }
  }, [isLogin, navigate]);
  return (
    <Layout
      selected="/regsiter-event"
      className="pt-24 flex h-full pb-12 bg-gray-50"
    >
      <Helmet title={t("REGISTER EVENT")} />
      <div className="w-full container mx-auto font-sans md:mt-5">
        <h1 className="text-3xl md:text-4xl font-bold font-sans text-red-600 mb-5 md:mb-6">
          {t("REGISTER EVENT")}
        </h1>
        <EventRegistration />
      </div>
    </Layout>
  );
};
export default RegisterEventPage;
