import { createSlice } from "@reduxjs/toolkit";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import useSWR from "swr";

interface User {
  id: number;
  name: string;
  type: string;
  email: string;
  email_verified_at?: string;
  age: number;
  occupation: string;
  company: string;
  phone: string;
  address: string;
  status: number;
}

interface UserState {
  isLogin: boolean;
  user: User;
  isloaded: boolean;
}

const initialState: UserState = {
  isLogin: null,
  user: {} as any,
  isloaded: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    checkUser: (state) => {
      //
    },
    setLogin: (state, { payload }) => {
      if (payload.token) {
        localStorage._token = payload.token;
      }
      state.user = payload.user;
      state.isloaded = true;
      state.isLogin = true;
    },
    setLogout: (state, { payload }) => {
      localStorage.removeItem("_token");
      state.user = {} as any;
      state.isloaded = true;
      state.isLogin = false;
    },
  },
});

export const AuthManager = () => {
  const { data, error } = useSWR("/me");
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage._token) {
      if (data) {
        dispatch(userSlice.actions.setLogin({ user: data }));
      }
      if (error) {
        if (error?.response?.status === 401) {
          dispatch(userSlice.actions.setLogout({}));
        }
      }
    } else {
      dispatch(userSlice.actions.setLogout({}));
    }
  }, [data, error, dispatch]);

  return <></>;
};

export const { checkUser, setLogout, setLogin } = userSlice.actions;

export default userSlice.reducer;
