import React from "react";
import tw, { styled } from "twin.macro";

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  color?: "primary" | "secondary" | "white";
  size?: "sm" | "md" | "lg";
  outline?: boolean;
}

/** @ts-ignore */
const StyledButton = styled.button(({ color: c, outline: o, size }) => [
  tw`px-4 rounded-md py-2 appearance-none border`,
  tw`transform duration-200`,
  !o && tw`border-transparent`,
  c === "primary" && !o && tw`bg-red-600 hover:bg-red-700 text-white`,
  c === "primary" &&
    o &&
    tw`border-red-600 hover:bg-red-700 text-red-600 hover:text-white`,

  c === "secondary" && !o && tw`bg-gray-700 hover:bg-gray-800 text-white`,
  c === "secondary" &&
    o &&
    tw`border-gray-700 hover:bg-gray-800 text-gray-700 hover:text-white`,

  c === "white" && !o && tw`bg-white hover:bg-gray-200 text-gray-700`,
  c === "white" &&
    !!o &&
    tw`border-white text-white hover:bg-gray-200 hover:text-gray-700`,
  size === "sm" && tw`px-2 py-1 text-sm`,
]);
const Button = (props: Props) => {
  return <StyledButton {...props} />;
};

Button.defaultProps = {
  color: "secondary",
};

export default Button;
