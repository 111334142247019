import {
  Link,
  RouteComponentProps,
  useLocation,
  useNavigate,
} from "@reach/router";
import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Layout from "../Components/Layout";
import { RootState } from "../stores";
import Api from "../utils/api";

interface Props extends RouteComponentProps {}

const EmailVerificationPage = (props: Props) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState<"PENDING" | "SUCCESS" | "FAILED">(
    "PENDING"
  );
  const navigate = useNavigate();
  const location = useLocation();
  const { isLogin } = useSelector((s: RootState) => s.user);

  useEffect(() => {
    const query = new URLSearchParams(location.search);

    const token = query.get("token");
    const user_id = query.get("user_id");

    const verifyEmail = async () => {
      try {
        await Api.post("/verify/email", { token, user_id });
        setStatus("SUCCESS");
      } catch (e) {
        setStatus("FAILED");
      }
    };
    if (token && user_id) {
      verifyEmail();
    } else {
      navigate("/");
    }
  }, [navigate, location.search]);

  useEffect(() => {
    if (isLogin) {
      navigate("/");
    }
  }, [isLogin, navigate]);

  return (
    <Layout selected="/email-verification" className="h-full flex">
      <Helmet title={t("Email Verification")} />
      <div className="pt-28 container flex items-center justify-center flex-1 mx-auto font-sans">
        <div className="flex flex-col md:flex-row md:divide-x-2 w-full md:w-auto">
          <div className="md:pr-8 hidden md:block">
            <img
              src={process.env.PUBLIC_URL + "/images/login-illustration.jpg"}
              className="-mb-8 -mt-8"
              alt="email"
            />
          </div>
          <div className="md:pl-8 pb-8 md:pb-0">
            <div className="p-4 sm:w-96">
              <h2 className="text-2xl">{t("Email Verification")}</h2>

              <div className="mt-5">
                {status === "PENDING" && (
                  <>
                    <div className="w-8/12 h-4 rounded-md bg-gray-300 animate-pulse" />
                    <div className="w-6/12 mt-3 h-4 rounded-md bg-gray-300 animate-pulse" />
                    <div className="w-9/12 mt-3 h-4 rounded-md bg-gray-300 animate-pulse" />
                  </>
                )}
                {status === "SUCCESS" && (
                  <>
                    <p>
                      {t("Congratulations!, your email Has been verified!")}
                    </p>
                    <p className="mt-2">
                      {t("Please")}{" "}
                      <Link className="text-red-600 underline" to="/login">
                        {t("Click Here")}
                      </Link>{" "}
                      {t("To Continue")}
                    </p>
                  </>
                )}

                {status === "FAILED" && (
                  <>
                    <p>
                      {t(
                        "Oh no!, the token is invalid or it has been expired!"
                      )}
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EmailVerificationPage;
