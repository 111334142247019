import { RouteComponentProps } from "@reach/router";
import React from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import Layout from "../Components/Layout";
import MailIcon from "../icons/MailIcon";
import MarkerIcon from "../icons/MarkerIcon";
import PhoneIcon from "../icons/PhoneIcon";
interface Props extends RouteComponentProps {}

const ContactPage = (props: Props) => {
  const { t } = useTranslation();
  return (
    <Layout selected="/contact" className="pt-24 flex h-full pb-12 bg-gray-50">
      <Helmet title={t("Contact Us")} />
      <div className="w-full container mx-auto font-sans md:mt-5">
        <h1 className="text-3xl md:text-4xl font-bold font-sans text-red-600 mb-5 md:mb-6">
          {t("Contact Us")}
        </h1>
        <div className="flex flex-wrap -m-4">
          <div className="w-full md:w-4/12 p-4">
            <div className="bg-white shadow-xl p-8 h-full rounded-lg text-center transition-all duration-500 hover:shadow-md">
              <p className="w-16 h-16 bg-red-600 rounded-full p-4 mx-auto mb-4 flex justify-center items-center">
                <MarkerIcon className="text-white" />
              </p>
              <h2 className="mb-4 text-xl font-semibold">
                {t("Office Address")}
              </h2>
              <a
                href="https://www.google.com/maps/place/Pandi+-+.Id+Registry/@-6.3174209,106.6495851,15z/data=!4m12!1m6!3m5!1s0x0:0xd98c8b9d3cd44465!2sPandi+-+.Id+Registry!8m2!3d-6.3174209!4d106.6495851!3m4!1s0x0:0xd98c8b9d3cd44465!8m2!3d-6.3174209!4d106.6495851"
                rel="noreferrer"
                target="_blank"
              >
                <p className="mb-2">Icon Business Park Unit L1-L2 BSD City</p>
                <p>Tangerang 15345. Indonesia</p>
              </a>
            </div>
          </div>
          <div className="w-full md:w-4/12 p-4">
            <div className="bg-white shadow-xl p-8 h-full rounded-lg text-center transition-all duration-500 hover:shadow-md">
              <p className="w-16 h-16 bg-red-600 rounded-full p-4 mx-auto mb-4 flex justify-center items-center">
                <MailIcon className="text-white w-8 h-8" />
              </p>
              <h2 className="mb-4 text-xl font-semibold">{t("Email")}</h2>
              <p className="mb-2">
                <a href="mailto:info@pandi.id">info@pandi.id</a>
              </p>
              <p>
                <a href="mailto:helpdesk@pandi.id">helpdesk@pandi.id</a>
              </p>
            </div>
          </div>

          <div className="w-full md:w-4/12 p-4">
            <div className="bg-white shadow-xl p-8 h-full rounded-lg text-center transition-all duration-500 hover:shadow-md">
              <p className="w-16 h-16 bg-red-600 rounded-full p-4 mx-auto mb-4 flex justify-center items-center">
                <PhoneIcon className="text-white w-6 h-6" />
              </p>
              <h2 className="mb-4 text-xl font-semibold">{t("Telephone")}</h2>
              <p className="mb-2">+62 21 30055777</p>
              <p>WA: +62 811 8805 530</p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ContactPage;
