import React from "react";
// import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";

const InfoIBD = () => {
  // const { t } = useTranslation();
  return (
    <>
      <h1 className="text-center text-xl md:text-3xl font-semi-bold mb-3 font-sans">
        {/* {t("lomba.join")} */}
      </h1>

      <Swiper
        loop
        speed={1000}
        autoplay={{
          delay: 2000,
        }}
        pagination={{
          clickable: true,
        }}
      >
        <SwiperSlide className="flex">
          <div className="mb-5 md:mb-20">
            <div className="flex flex-col md:flex-row md:items-center">
              <img
                className="transition md:w-4/12 md:order-1"
                src="/images/ibd/peserta.jpeg"
                alt="lomba"
              />
              <div className="text-center md:text-left md:w-8/12 md:px-6">
                <h1 className="font-bold text-2xl lg:text-4xl font-sans mb-3">
                  ABOUT INDONESIA BERDAULAT DIGITAL
                </h1>
                <p className="text-justify mb-3">
                  PANDI will held an event Indonesia Berdaulat Digital in 2024
                  as an act to gather all stakeholders in the domain industry to
                  discuss and showcase the journey of domain industry in
                  Indonesia. In a policy context, this event is also an input
                  for the government.
                </p>
                <p className="text-justify">
                  Indonesia Berdaulat Digital is the theme of the upcoming event
                  because it aims to raise awareness of the importance of the
                  developments in technology industry and digital world.
                  Cooperation is needed from every segment, especially :
                  government, academics, stakeholders and public. To develop the
                  internet ecosystem in Indonesia. This effort was made to raise
                  the "Indonesia Berdaulat Digital"
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="flex">
          <div className="mb-5 md:mb-10">
            <div className="flex flex-col md:flex-row md:items-center">
              <img
                className="transition md:w-4/12 md:order-1"
                src="/images/ibd/id_image.JPG"
                alt="lomba"
              />
              <div className="text-center md:text-left md:w-8/12 md:px-6">
                <h1 className="font-bold text-2xl lg:text-4xl font-sans mb-3">
                  ABOUT ORGANIZER
                </h1>
                <p className="text-justify">
                  Pengelola Nama Domain Internet Indonesia (PANDI) is the
                  Indonesian Top Level Domain Name Registry (.ID) assigned by
                  the Minister of Communication and Information Technology of
                  the Republic of Indonesia by Decree no. 806 of 2014. The main
                  purpose of PANDI is to make the .id domain as the main domain
                  of choice at the national level and as the preferred domain at
                  the international level.
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        {/* <SwiperSlide className="flex">
          <div className="mb-5 md:mb-10">
            <div className="flex flex-col md:flex-row md:items-center">
              <iframe
                className="transition md:w-4/12 md:order-1"
                width="420"
                height="315"
                src={`https://www.youtube.com/embed/D4KAQzHBTig`}
                frameBorder="0"
                allowFullScreen></iframe>
              <div className="text-center md:text-left md:w-8/12 md:px-6">
                <h1 className="font-bold text-2xl lg:text-4xl font-sans mb-3">
                  Reels
                </h1>
                <p className="lg:text-lg font-sans text-justify">
                  Ketentuan mengikuti lomba reels:
                </p>
                <ul className="font-sans">
                  <li>
                    1. Follow instagram PANDI{" "}
                    <span className="text-red-600 font-semi-bold">
                      @pandi.id
                    </span>{" "}
                  </li>
                  <li>2. Buat reels mengenai ajakan mengikuti acara ID-RES.</li>
                  <li>
                    3. Buat caption semenarik mungkin dan jangan lupa untuk tag
                    minimal 3 orang teman kamu.
                  </li>
                  <li>
                    4. Tag instagram PANDI @pandi_id disertai dengan hashtag
                    <span className="text-red-600 font-semi-bold">
                      #IndonesiaDigitalResilience
                    </span>
                  </li>
                  <li>5. Posting di reels instagram kamu.</li>
                  <li>
                    6. Akun tidak boleh private dan harus menggunakan akun asli.
                  </li>
                  <li>7. Melakukan pendaftaran di meeting.pandi.id</li>
                </ul>
                <p>- Batas Pengiriman: 8 - 20 Agustus 2022</p>
                <p>
                  - Tersedia Hadiah Uang Tunai dan Merchandise Menarik untuk 3
                  Orang pemenang
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide> */}
      </Swiper>
    </>
  );
};

export default InfoIBD;
