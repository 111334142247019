import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useSWR from "swr";
import { useWithLang } from "../i18n";
import { RootState } from "../stores";
import { ApiResponse } from "../utils/api";
import { RundownEvent } from "../utils/types";
import { Form, Formik, Field } from "formik";
import Button from "../Components/Button";
import FormLoading from "../Components/FormLoading";
import { useNavigate } from "@reach/router";
import Api from "../utils/api";
import { toast } from "react-hot-toast";
import ClockIcon from "../icons/ClockIcon";
import { Fragment } from "react";

interface Props {}

const EventRegistration = (props: Props) => {
  const { isLogin } = useSelector((state: RootState) => state.user);
  const { f } = useWithLang();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: d } = useSWR<
    ApiResponse<{ date: string; lists: RundownEvent[] }[]>
  >(() => f(isLogin ? "/users/events" : "/public/events"), {
    initialData: {
      data: [],
    } as any,
  });
  const [formValues, setFormValues] = useState<{ [key: number]: boolean }>({});
  const [selectValues, setSelectValues] = useState<{ [key: number]: string }>(
    {}
  );

  useEffect(() => {
    if (d?.data) {
      const initialSelectValues = d.data.reduce((acc, item) => {
        item.lists.forEach((event) => {
          acc[event.id] = event.type || "offline";
        });
        return acc;
      }, {});
      setSelectValues(initialSelectValues);
    }
  }, [d]);

  const onSubmit = async (values, { setErrors }) => {
    // console.log(values);
    try {
      const requestData = values.map((event) => ({
        ...event,
        is_register: formValues[event.id] || false,
        // type: selectValues[event.id] || "online",
      }));
      // console.log("request Data", requestData);
      const { data } = await Api.post("/register-event", requestData);
      toast.success(
        <>
          <strong>{t("Thank you for registering")}</strong>
        </>
      );
      navigate("/");
    } catch (e) {
      toast.error(e.response?.data?.message || "Internal Server Error!");
    }
  };

  const eventLists = useMemo(() => {
    if (d.data.length === 0) {
      return [];
    }

    const events = [];
    d.data.forEach((dt) => {
      dt.lists.forEach((list) => {
        if (list.title !== "Expo") {
          const event = {
            id: list.id,
            title: list.title,
            type: list.title === "Expo" ? "offline" : "offline",
            is_register: false, // Initialize is_register field to false
          };
          events.push(event);
        }
      });
    });
    return events;
  }, [d]);

  if (d?.data?.length === 0) {
    return <></>;
  }

  const handleCheckboxChange = (eventId: number, checked: boolean) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [eventId]: checked,
    }));
  };

  const handleSelectChange = (eventId: number, value: string) => {
    // console.log(`Event ID: ${eventId}, Value: ${value}`);
    setSelectValues((prevValues) => ({
      ...prevValues,
      [eventId]: value,
    }));
    // console.log("Select Values:", selectValues);
  };

  return (
    <div className="event-rundown mb-8 border border-gray-300 rounded-lg font-sans">
      <div className="bg-gray-100 p-4 md:p-6 rounded-b-lg">
        <Formik initialValues={eventLists} onSubmit={onSubmit}>
          {({ isSubmitting }) => (
            <Form>
              <FormLoading isLoading={isSubmitting}>
                {d.data.map((item, index) => (
                  <div key={index}>
                    <h2 className="font-bold mt-4">
                      {t("DAY")} {index + 1}
                    </h2>
                    <h5 className="mb-2">
                      {t("Date")} {item.date}
                    </h5>
                    <hr />
                    <div className="flex flex-col">
                      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="py-4 inline-block min-w-full sm:px-6 lg:px-8">
                          <div className="overflow-hidden">
                            <table className="min-w-full text-center">
                              <thead className="border-b bg-gray-500">
                                <tr>
                                  <th
                                    scope="col"
                                    className="text-sm font-bold text-gray-900 px-6 py-4"
                                  >
                                    {t("Attendance")}
                                  </th>
                                  <th
                                    scope="col"
                                    className="text-sm font-bold text-gray-900 px-6 py-4"
                                  >
                                    {t("Title")}
                                  </th>
                                  <th
                                    scope="col"
                                    className="text-sm font-bold text-gray-900 px-6 py-4"
                                  >
                                    {t("Hour")}
                                  </th>
                                  <th
                                    scope="col"
                                    className="text-sm font-bold text-gray-900 px-6 py-4"
                                  >
                                    {t("Select Attendance")}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {item.lists.map((event, index) => (
                                  <Fragment key={index}>
                                    {event.title !== "Expo" && (
                                      <>
                                        <tr
                                          className="bg-white border-b"
                                          key={index}
                                        >
                                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                            <Field
                                              name={`events[${event.id}].is_register`}
                                            >
                                              {({ field }) => (
                                                <input
                                                  {...field}
                                                  type="checkbox"
                                                  onChange={(e) => {
                                                    // console.log(
                                                    //   `Event ID: ${event.id}`
                                                    // );
                                                    // console.log(
                                                    //   `Current value: ${field.value}`
                                                    // );
                                                    // console.log(
                                                    //   `New value: ${e.target.checked}`
                                                    // );
                                                    handleCheckboxChange(
                                                      event.id,
                                                      e.target.checked
                                                    );
                                                  }}
                                                  className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                />
                                              )}
                                            </Field>
                                          </td>
                                          <td className="text-sm text-gray-900 font-light px-6 py-4">
                                            {event.title}
                                          </td>
                                          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                            <div className="flex items-center mb-2 text-gray-500">
                                              <div className="mr-3">
                                                <ClockIcon />
                                              </div>
                                              <time className="block">
                                                {event.hour_start
                                                  .toString()
                                                  .padStart(2, "0")}
                                                :
                                                {event.minute_start
                                                  .toString()
                                                  .padStart(2, "0")}{" "}
                                                -{" "}
                                                {event.hour_end
                                                  .toString()
                                                  .padStart(2, "0")}
                                                :
                                                {event.minute_end
                                                  .toString()
                                                  .padStart(2, "0")}
                                              </time>
                                            </div>
                                          </td>
                                          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                            <Field name={`${index}.type`}>
                                              {({ field }) => (
                                                <select
                                                  {...field}
                                                  value={
                                                    selectValues[event.id] ||
                                                    "online"
                                                  }
                                                  onChange={(e) =>
                                                    handleSelectChange(
                                                      event.id,
                                                      e.target.value
                                                    )
                                                  }
                                                  className="ml-4 p-2 rounded-md border-gray-300 focus:border-red-600 focus:ring-red-300 focus:ring-4"
                                                  disabled={
                                                    event.title === "Expo"
                                                  }
                                                >
                                                  {/* <option value="">
                                                    Select Attendence Type
                                                  </option> */}
                                                  {/* <option value="online">
                                                    Online
                                                  </option> */}
                                                  <option value="offline">
                                                    Offline
                                                  </option>
                                                </select>
                                              )}
                                            </Field>
                                          </td>
                                        </tr>
                                      </>
                                    )}
                                  </Fragment>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <Button
                  type="submit"
                  color="primary"
                  className="font-bold my-3"
                >
                  {t("REGISTER EVENT")}
                </Button>
              </FormLoading>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EventRegistration;
