import { RouteComponentProps, useNavigate } from "@reach/router";
import { Form, Formik, Field as FormikField } from "formik";
import React from "react";
// import React, { useState } from "react";
import Helmet from "react-helmet";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import Button from "../Components/Button";
import ErrorMessage from "../Components/ErrorMessage";
import FormLoading from "../Components/FormLoading";
import Field from "../Components/InputField";
import Layout from "../Components/Layout";
import Api, { parseErrorValidation } from "../utils/api";

interface Props extends RouteComponentProps {}

const validation = Yup.object().shape({
  name: Yup.string().required(),
  email: Yup.string().email().required(),
  domain: Yup.string().required(),
});

const initialValues = {
  name: "",
  domain: "",
  email: "",
  suggestion: "tidak setuju",
  suggestion_1: "",
  suggestion_2: "",
  suggestion_3: "",
};

const SuggestionPage = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onSubmit = async (values, { setErrors }) => {
    try {
      //make object
      const suggestionData = {
        name: values.name,
        email: values.email,
        domain: values.domain,
        suggestion: {
          "Apakah Anda Setuju terhadap penurunan harga nama domain .biz.id?":
            values.suggestion,
          "Masukan Mengenai Perlindungan Data Pribadi": values.suggestion_1,
          "Masukan Mengenai Kebijakan PANDI": values.suggestion_2,
          "Masukan Mengenai Layanan Nama Domain .id": values.suggestion_3,
        },
      };
      await Api.post("/public/suggestion", suggestionData);
      toast.success(t("suggestion_notif"));
      navigate("/");
    } catch (e) {
      if (e.response?.data?.errors) {
        setErrors(parseErrorValidation(e.response?.data?.errors));
      } else {
        toast.error(
          e.response?.data?.message || "Something went wrong!, please try again"
        );
      }
    }
  };

  return (
    <Layout selected="" className="h-full flex">
      <Helmet title={t("Register")} />
      <div className="pt-20 md:pt-28 container flex flex-col justify-center flex-1 mx-auto font-sans">
        <h2 className="text-center text-xl font-bold">SURVEI LAYANAN .ID</h2>
        <div className="flex flex-col items-center w-full md:flex-row md:divide-x-2 md:w-auto md:py-5">
          <div className="md:pr-8 hidden md:block w-5/12">
            <img
              src={process.env.PUBLIC_URL + "/images/register-illustration.jpg"}
              className="-mb-8 -mt-8 w-full"
              alt="login"
            />
          </div>
          <div className="md:pl-8 pb-8 md:pb-0 md:w-7/12">
            <div className="p-2 w-full sm:w-full">
              <Formik
                validationSchema={validation}
                initialValues={{ ...initialValues }}
                onSubmit={onSubmit}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <FormLoading isLoading={isSubmitting}>
                      <div className="flex flex-wrap -mx-4">
                        <div className="p-2 w-full">
                          <label className="block mb-2 font-bold">
                            {t("Full Name")}
                          </label>
                          <Field
                            className="form-input w-full"
                            name="name"
                            type="text"
                            placeholder={t("Full Name")}
                          />
                          <ErrorMessage name="name" />
                        </div>

                        <div className="p-2 w-full">
                          <label className="block mb-2 font-bold">Domain</label>
                          <Field
                            className="form-input w-full"
                            name="domain"
                            type="type"
                            placeholder={t("domain_name")}
                          />
                          <ErrorMessage name="domain" />
                        </div>

                        <div className="p-2 w-full">
                          <label className="block mb-2 font-bold">
                            {t("Email")}
                          </label>
                          <Field
                            className="form-input w-full"
                            name="email"
                            type="email"
                            placeholder={t("Email")}
                          />
                          <ErrorMessage name="email" />
                        </div>

                        <div className="p-2 w-full">
                          <label className="block mb-2 font-bold">
                            Apakah Anda Setuju terhadap penurunan harga nama
                            domain .biz.id?
                          </label>
                          <FormikField
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            name="suggestion"
                            type="radio"
                            value="setuju"
                          />
                          <label
                            htmlFor="default-radio-1"
                            className="mx-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            Setuju
                          </label>
                          <FormikField
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            name="suggestion"
                            type="radio"
                            value="tidak setuju"
                          />
                          <label
                            htmlFor="default-radio-1"
                            className="mx-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            Tidak Setuju
                          </label>
                          <ErrorMessage name="suggestion" />
                        </div>

                        <div className="p-2 w-full">
                          <label className="block mb-2 font-bold">
                            Masukan Mengenai Perlindungan Data Pribadi
                            <span className="text-red-700 text-xs mx-1">
                              *optional
                            </span>
                          </label>
                          <Field
                            className="form-input w-full"
                            name="suggestion_1"
                            component="textarea"
                            placeholder=""
                          />
                          <ErrorMessage name="suggestion_1" />
                        </div>

                        <div className="p-2 w-full">
                          <label className="block mb-2 font-bold">
                            Masukan Mengenai Kebijakan PANDI
                            <span className="text-red-700 text-xs mx-1">
                              *optional
                            </span>
                          </label>
                          <Field
                            className="form-input w-full"
                            name="suggestion_2"
                            component="textarea"
                            placeholder=""
                          />
                          <ErrorMessage name="suggestion_2" />
                        </div>

                        <div className="p-2 w-full">
                          <label className="block mb-2 font-bold">
                            Masukan Mengenai Layanan Nama Domain .id
                            <span className="text-red-700 text-xs mx-1">
                              *optional
                            </span>
                          </label>
                          <Field
                            className="form-input w-full"
                            name="suggestion_3"
                            component="textarea"
                            placeholder=""
                          />
                          <ErrorMessage name="suggestion_3" />
                        </div>

                        <div className="p-2 w-full">
                          <Button
                            type="submit"
                            color="primary"
                            className="font-bold w-full"
                          >
                            Beri Tanggapan
                          </Button>
                        </div>
                      </div>
                    </FormLoading>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SuggestionPage;
