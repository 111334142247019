import React from "react";

interface Props {
  isLoading: boolean;
  children: React.ReactNode;
}

const FormLoading = ({ isLoading, children }: Props) => {
  return (
    <div className="relative">
      {children}
      {isLoading && (
        <div className="blockr absolute top-0 left-0 bottom-0 right-0 bg-white bg-opacity-50 flex justify-center items-center">
          <div
            className="loading p-4 rounded-md bg-white border border-gray-100
           shadow-lg flex flex-col items-center"
          >
            <div>
              <svg
                className="animate-spin mb-1 h-5 w-5 text-red-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </div>
            <h1 className="text-gray-600">Please Wait...</h1>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormLoading;
