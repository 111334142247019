import { RouteComponentProps } from "@reach/router";
import React from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import Layout from "../Components/Layout";
import { useWithLang } from "../i18n";
import { ApiResponse } from "../utils/api";
import { SettingProp } from "../utils/types";
interface Props extends RouteComponentProps {}

const AboutPage = (props: Props) => {
  const { t } = useTranslation();
  const { f } = useWithLang();
  const { data } = useSWR<ApiResponse<SettingProp>>(f("/public/pages/about"), {
    initialData: null,
  });
  return (
    <Layout selected="/about" className="pt-24 flex h-full pb-12">
      <Helmet title={t("About")} />
      <div className="flex flex-wrap container mx-auto font-sans md:divide-x-2">
        <div className="w-full md:w-5/12 md:pr-4 pb-6 md:pb-0 flex flex-col items-center md:items-start">
          <h1 className="text-3xl md:text-4xl font-bold font-sans text-red-600 mb-5 md:mb-6">
            {t("ABOUT")}
          </h1>
          <div
            className="video relative w-full"
            style={{ paddingBottom: "55.5%" }}
          >
            {/* <iframe
              src="https://www.youtube.com/embed/-2o0qNh3OLU"
              title="YouTube video player"
              frameBorder={0}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="absolute top-0 left-0 w-full h-full"
            /> */}
            <img
              src="/images/ibd/logo_ibd.jpeg"
              alt="logo-IBD"
              className="my-2"
            />
          </div>
        </div>
        <div className="md:w-7/12 md:pl-4 leading-loose text-lg text-justify">
          {data === null ? (
            <>
              {[...new Array(3)].map((_, index) => (
                <div className="mb-8">
                  <div className="bg-gray-300 mb-4 animate-pulse h-4 rounded-md w-11/12" />
                  <div className="bg-gray-300 mb-4 animate-pulse h-4 rounded-md w-10/12" />
                  <div className="bg-gray-300 mb-4 animate-pulse h-4 rounded-md w-11/12" />
                  <div className="bg-gray-300 mb-4 animate-pulse h-4 rounded-md w-9/12" />
                </div>
              ))}
            </>
          ) : (
            <div>
              {data?.data?.value?.split("\n").map((content, index) => (
                <p className="mb-3" key={index}>
                  {content}
                </p>
              ))}
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default AboutPage;
