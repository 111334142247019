import { RouteComponentProps } from "@reach/router";
import React from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { SpeakerItem } from "../Components/EventSpeakers";
import Layout from "../Components/Layout";
import { useWithLang } from "../i18n";
import { ApiResponse } from "../utils/api";
import { Speaker } from "../utils/types";
interface Props extends RouteComponentProps {}

const SpeakersPage = (props: Props) => {
  const { t } = useTranslation();
  const { f } = useWithLang();
  const { data } = useSWR<ApiResponse<Speaker[]>>(f("/public/speakers"));

  return (
    <Layout selected="/speakers" className="pt-24 flex h-full pb-12">
      <Helmet title={t("Speakers")} />
      <div className="flex flex-col flex-wrap container mx-auto font-sans">
        <h1 className="font-sans font-bold text-4xl text-red-600 w-full">
          {t("Speakers")}
        </h1>

        <div className="w-full">
          <div className="mt-10 flex flex-wrap -m-4">
            {data?.data?.map((item, index) => {
              return (
                <SpeakerItem
                  key={index}
                  item={item}
                  className="w-6/12 md:w-3/12 p-4"
                />
              );
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SpeakersPage;
