import { Link } from "@reach/router";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { useWithLang } from "../i18n";
import { ApiResponse, getBaseURL } from "../utils/api";
import { Speaker } from "../utils/types";
import Button from "./Button";

interface Props {}

export const SpeakerItem = ({
  item,
  className,
}: {
  item: Speaker;
  className: string;
}) => {
  return (
    <div className={clsx("flex flex-col items-center text-center", className)}>
      <figure className="w-28 h-28 md:w-40 md:h-40 bg-white overflow-hidden rounded-full mb-4 flex justify-center items-center">
        <img src={getBaseURL(item.image)} alt={item.name} />
      </figure>
      <div>
        <h2 className="font-sans md:text-xl font-semibold mb-2">
          {item.name || "Unnamed"}
        </h2>
        <h3 className="font-sans text-red-600 text-sm md:text-base">
          {item.occupation || "Unknown"}
        </h3>
      </div>
    </div>
  );
};

const EventSpeakers = (props: Props) => {
  const { t } = useTranslation();
  const { f } = useWithLang();

  const { data: d } = useSWR<ApiResponse<Speaker[]>>(f("/public/speakers"), {
    revalidateOnMount: true,
    initialData: {} as any,
  });

  return (
    <div className="container mx-auto">
      <div className="flex items-center mb-12">
        <div className="mr-3">
          <img
            src="/images/pandi.png"
            className="w-14 md:w-auto"
            alt="pandi-light"
          />
        </div>
        <h2 className="text-xl md:text-3xl font-semibold font-sans ">
          {t("EVENT SPEAKERS")}{" "}
          <span className="font-sans font-normal italic">
            / {t("Meet With Greates")}
          </span>
        </h2>
      </div>

      <div className="flex flex-wrap flex-col md:flex-row">
        {d?.data?.length > 0 &&
          d?.data?.slice(0, 4).map((item, index) => {
            return (
              <SpeakerItem
                key={index}
                item={item}
                className="md:w-3/12 pb-10 md:pb-0 "
              />
            );
          })}
      </div>
      <div className="flex justify-center mt-10">
        <Link to="/speakers">
          <Button color="primary" className="font-sans">
            {t("SEE ALL SPEAKERS")}
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default EventSpeakers;
