import { Menu, Transition } from "@headlessui/react";
import { Link, useNavigate } from "@reach/router";
import clsx from "clsx";
import React, { Fragment } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../stores";
import { setLogout } from "../stores/features/user";
import splitName from "../utils/splitName";
import Button from "./Button";
import EditProfile from "./EditProfile";
import Modal, { useModal } from "./Modal";

const AuthButton = ({ transparent = false, selected = "" }) => {
  const { user, isLogin, isloaded } = useSelector(
    (state: RootState) => state.user
  );
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const logoutModal = useModal();
  const editModal = useModal();

  const menuItems = [
    { href: "#profile", label: t("Profile") },
    // { href: "/certificate", label: t("Certificate") },
    { href: "#logout", label: t("Logout") },
  ];

  const onClickMenu = ({ href }) => {
    if (href === "#logout") {
      logoutModal.setIsOpen(true);
      return;
    }
    if (href === "#profile") {
      editModal.setIsOpen(true);
      return;
    }
    navigate(href);
  };

  const onDoLogout = () => {
    dispatch(setLogout({}));
    toast.success(t("Logout Successful!"));
    logoutModal.setIsOpen(false);
  };

  if (!isloaded) {
    return (
      <div className="px-8 py-3 md:px-3 md:py-1 items-center flex h-full">
        <div
          className={clsx("w-16 rounded-md h-4 opacity-50 animate-pulse", {
            "bg-white": transparent,
            "bg-black": !transparent,
          })}
        />
      </div>
    );
  }

  if (isLogin === true) {
    return (
      <>
        <Menu>
          <Menu.Button>
            <button
              className={clsx(
                "block px-8 py-3 md:px-3 md:py-1",
                { "text-white": transparent },
                { "text-gray-800": !transparent }
              )}>
              <strong className="underline">{splitName(user.name)}</strong>
            </button>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items className="mt-2 absolute md:origin-top-right md:right-0 w-40 bg-white overflow-hidden rounded-md shadow-md">
              {menuItems.map((item, index) => (
                <Menu.Item key={index}>
                  <button
                    onClick={() => onClickMenu(item)}
                    className="px-3 py-2 hover:bg-gray-200 appearance-none w-full text-left">
                    {item.label}
                  </button>
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </Menu>
        <Modal {...logoutModal} className="bg-white p-4 rounded-lg">
          <h2 className="text-xl font-semibold">{t("Confirm Logout")}</h2>
          <p className="mt-2">{t("Are you sure want to logout?")}</p>
          <div className="flex justify-end mt-6">
            <Button
              className="mr-3"
              onClick={() => logoutModal.setIsOpen(false)}>
              {t("No")}
            </Button>
            <Button color="primary" onClick={onDoLogout}>
              {t("Logout")}
            </Button>
          </div>
        </Modal>
        <EditProfile {...editModal} />
      </>
    );
  }

  return (
    <>
      <Link
        to="/login"
        className={clsx(
          "block px-8 py-3 md:px-3 md:py-1",
          { "text-white": transparent },
          { "text-gray-800": !transparent }
        )}
        style={{
          color: selected === "/login" ? "#fff" : "",
        }}>
        {t<string>("Login")}
      </Link>
    </>
  );
};

export default AuthButton;
