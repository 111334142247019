import { RouteComponentProps, useNavigate } from "@reach/router";
import moment from "moment";
import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useSWR from "swr";
import Button from "../Components/Button";
import Layout from "../Components/Layout";
import { useWithLang } from "../i18n";
import Printicon from "../icons/PrintIcon";
import { RootState } from "../stores";
import { ApiResponse } from "../utils/api";
import { Certificate } from "../utils/types";

interface Props extends RouteComponentProps {}

const CertificatePage = (props: Props) => {
  const { isLogin, isloaded } = useSelector((s: RootState) => s.user);
  const navigate = useNavigate();
  const { f } = useWithLang();
  const { t } = useTranslation();

  const { data, isValidating } = useSWR<ApiResponse<Certificate[]>>(
    f("/users/certificates"),
    {
      initialData: {
        data: [],
        success: false,
      },
    }
  );

  useEffect(() => {
    if (isloaded && !isLogin) {
      navigate("/login");
    }
  }, [isloaded, isLogin, navigate]);

  if (!isloaded) {
    return <></>;
  }

  return (
    <Layout selected="/certificate" className="h-full flex">
      <Helmet title={t("Certificate")} />
      <div className="pt-28 container mx-auto">
        <div>
          <h1 className="text-3xl md:text-4xl font-bold font-sans text-red-600 mb-5 md:mb-6">
            {t("Certificate")}
          </h1>
          <div className="mt-2">
            {isValidating && data.data.length === 0 ? (
              <>
                {[...new Array(4)].map((_, index) => (
                  <div
                    key={index}
                    className="mb-3 border border-gray-200 p-3 rounded-md shadow-md flex"
                  >
                    <div className="flex-1">
                      <div className="bg-gray-300 w-9/12 h-5 md:w-6/12 rounded-md animate-pulse mb-2" />
                      <div className="bg-gray-300 h-4 w-20 rounded-md animate-pulse" />
                    </div>
                    <div className="bg-gray-300 h-5 w-20 rounded-md animate-pulse" />
                  </div>
                ))}
              </>
            ) : (
              <>
                {data.data.map((item) => (
                  <div className="mb-3 border border-gray-200 p-3 rounded-md shadow-md flex items-start">
                    <div className="flex-1 pr-3 mt-1">
                      <h2 className="text-lg font-semibold">
                        {item.certificate.title}
                      </h2>
                      <time className="text-gray-500 font-semibold">
                        {moment(item.created_at).format("DD/MM/YYYY")}
                      </time>
                    </div>
                    <div>
                      <a
                        href={`${process.env.REACT_APP_BASE_URL}/users/certificates/${item.uuid}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Button size="sm" className="flex" color="primary">
                          <span className="mr-2">
                            <Printicon />
                          </span>
                          <span className="font-semibold">{t("Download")}</span>
                        </Button>
                      </a>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CertificatePage;
