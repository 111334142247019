import { Link } from "@reach/router";
import React from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import Button from "../Components/Button";
import Layout from "../Components/Layout";
interface Props {
  default: boolean;
}

const NotFoundPage = (props: Props) => {
  const { t } = useTranslation();
  return (
    <Layout selected="" className="flex flex-col justify-center">
      <Helmet title="404 Not Found" />
      <div className="flex flex-col items-center justify-center h-full container mx-auto font-sans text-center">
        <h1 className="text-5xl font-bold">404 Not Found</h1>
        <p className="mt-4 text-xl">
          {t("Sorry, the page you are looking for could not be found")}
        </p>
        <div className="mt-4">
          <Link to="/">
            <Button>{t("Back")}</Button>
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
