import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import "swiper/swiper-bundle.min.css";
import { SWRConfig } from "swr";
import { GlobalStyles } from "twin.macro";
import App from "./App";
import i18n from "./i18n";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import store from "./stores";
import { AuthManager } from "./stores/features/user";
import { fetcher } from "./utils/api";

ReactDOM.render(
  <Suspense fallback={<></>}>
    <I18nextProvider i18n={i18n}>
      <SWRConfig value={{ fetcher, revalidateOnMount: true }}>
        <Provider store={store}>
          <AuthManager />
          <GlobalStyles />
          <App />
        </Provider>
      </SWRConfig>
    </I18nextProvider>
  </Suspense>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
