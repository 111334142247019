import { RouteComponentProps } from "@reach/router";
import React from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { EventPlanItem } from "../Components/EventPlans";
import Layout from "../Components/Layout";
interface Props extends RouteComponentProps {}

const EventPricePage = (props: Props) => {
  const { t } = useTranslation();
  return (
    <Layout selected="/price" className="pt-24 flex h-full pb-12">
      <Helmet title={t("Event Price Lists")} />
      <div className="flex flex-wrap flex-col items-start container mx-auto font-sans">
        <h1 className="font-sans font-bold text-4xl text-red-600">
          {t("Event Price Lists")}
        </h1>

        <div className="mt-10 w-full flex flex-wrap -m-2">
          {[...new Array(3)].map((_, index) => (
            <div className="w-full sm:w-6/12 md:w-4/12 p-2">
              <EventPlanItem
                item={{
                  name: "The title",
                  description: "Lorem",
                  photo: "",
                  price: 50000,
                  category_id: 1,
                  link: "/register?cat=1",
                  features: [
                    "features 1",
                    "features 2",
                    "features 3",
                    "features 4",
                    "features 5",
                  ],
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default EventPricePage;
