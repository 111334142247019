import { Router } from "@reach/router";
import { createServer, Response } from "miragejs";
import React from "react";
import { Toaster } from "react-hot-toast";
import "./App.css";
import AboutPage from "./Pages/about";
import CertificatePage from "./Pages/certificate";
import ContactPage from "./Pages/contact";
import EmailVerificationPage from "./Pages/email-verification";
import EventPricePage from "./Pages/event-price";
import ForgotPage from "./Pages/forgot";
import IndexPage from "./Pages/Index";
import LoginPage from "./Pages/login";
import NotFoundPage from "./Pages/not-found";
import RegisterPage from "./Pages/register";
import ResetPasswordPage from "./Pages/reset-password";
import SchedulePage from "./Pages/schedule";
import SpeakersPage from "./Pages/speakers";
import SponsorPage from "./Pages/sponsor";
import RegisterEventPage from "./Pages/register-event";
import SuggestionPage from "./Pages/suggestion";
import CallbackPage from "./Pages/callback";
// import AboutIbdPage from "./Pages/Ibd/about";

const mockJwt = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwiZnVsbE5hbWUiOiJCZWlkb3UiLCJpZCI6MSwiaWF0IjoxNTE2MjM5MDIyfQ.qZpIIrUnA1rlfrA6oIbgGnUdsz5fK6-vltloGN_qSCE`;

/** this is mock server */
if (process.env.NODE_ENV === "test") {
  const server = createServer({});
  server.get("/api/me", () => ({
    success: true,
    data: { user: { fullName: "Beidou" } },
  }));
  // server.get(
  //   "/api/me",
  //   () => new Response(401, {}, { message: "Unauthorized" })
  // );

  server.post("/api/auth/login", (_, { requestBody }) => {
    const data = JSON.parse(requestBody);
    if (data.email === "user@pandi.id" && data.password === "bismillah") {
      return {
        success: true,
        data: {
          token: mockJwt,
          user: {
            fullName: "Beidou",
            id: 1,
          },
        },
      };
    }
    return new Response(
      422,
      {},
      { success: false, message: "Email / password salah" }
    );
  });

  server.get("/api/public/speakers", () => ({
    success: true,
    data: [
      {
        id: 1,
        name: "Maskat",
        position: "Programmer PANDI",
        image: "/public/images/speakers/maskat.png",
        order: 1,
        status: 1,
        created_at: null,
        updated_at: null,
      },
    ],
  }));

  server.get("/api/public/events", () => ({
    success: true,
    data: [
      {
        date: "20-01-2021",
        lists: [
          {
            id: 1,
            title: "Webinar PANDI",
            description:
              "Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium tempora \n                    minima delectus officiis commodi quo modi natus rem maiores illo, hic officia illum sed ipsa soluta a veniam praesentium quae.",
            event_type_id: 1,
            date: "2021-08-19",
            day: 1,
            hour_start: 9,
            minute_start: 0,
            hour_end: 11,
            minute_end: 0,
            price: "0",
            status: 1,
            created_at: null,
            updated_at: null,
            deleted_at: null,
            type: {
              id: 1,
              name: "Webinar",
              created_at: null,
              updated_at: null,
            },
            speakers: [
              {
                id: 1,
                name: "Maskat",
                position: "Programmer PANDI",
                image: "/public/images/speakers/maskat.png",
                order: 1,
                status: 1,
                created_at: null,
                updated_at: null,
                pivot: {
                  event_id: 1,
                  speaker_id: 1,
                  type: "Moderator",
                },
              },
              {
                id: 2,
                name: "Halim Yusuf Rasyid",
                position: "IT Security PANDI",
                image: "/public/images/speakers/halim.png",
                order: 1,
                status: 1,
                created_at: null,
                updated_at: null,
                pivot: {
                  event_id: 1,
                  speaker_id: 2,
                  type: "Pembicara",
                },
              },
            ],
          },
        ],
      },
      {
        date: "20-02-2021",
        lists: [],
      },
      {
        date: "20-03-2021",
        lists: [],
      },
      {
        date: "20-04-2021",
        lists: [],
      },
    ],
  }));
}
/** --- */

function App() {
  return (
    <>
      <Router>
        <IndexPage path="/" />
        {/* <AboutIbdPage path="/about" /> */}
        <AboutPage path="/about" />
        <LoginPage path="/login" />
        {/* <CallbackPage path="/callback" /> */}
        <EmailVerificationPage path="/email-verification" />
        <SchedulePage path="/schedule" />
        <RegisterPage path="/register" />
        <RegisterEventPage path="/register-event" />
        <ResetPasswordPage path="/reset-password" />
        <SponsorPage path="/partners" />
        <SpeakersPage path="/speakers" />
        <EventPricePage path="/price" />
        <ForgotPage path="/forgot" />
        <ContactPage path="/contact" />
        <CertificatePage path="/certificate" />
        <SuggestionPage path="/suggestion" />
        <NotFoundPage default />
      </Router>
      <Toaster position="top-center" containerClassName="mt-24" />
    </>
  );
}

export default App;
