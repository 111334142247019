import { Link, RouteComponentProps, useNavigate } from "@reach/router";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import toast from "react-hot-toast";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import Button from "../Components/Button";
import ErrorMessage from "../Components/ErrorMessage";
import FormLoading from "../Components/FormLoading";
import Field from "../Components/InputField";
import Layout from "../Components/Layout";
import { RootState } from "../stores";
import Api, { parseErrorValidation } from "../utils/api";
import { BounceLoader } from "react-spinners";
interface Props extends RouteComponentProps {}

const validation = Yup.object().shape({
  name: Yup.string().required(),
  email: Yup.string().email().required(),
  password: Yup.string().required().min(8),
  password_confirmation: Yup.string()
    .required()
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
  age: Yup.number().required().min(13),
  occupation: Yup.string().required(),
  company: Yup.string().required(),
  phone: Yup.string()
    .matches(/\d{9,13}/, "Invalid phone number format")
    .required(),
  address: Yup.string().required(),
});

const initialValues = {
  name: "",
  email: "",
  password: "",
  password_confirmation: "",
  age: 13,
  occupation: "",
  company: "",
  phone: "",
  address: "",
};

const RegisterPage = (props: Props) => {
  const [panelState, setPanelState] = useState("IDLE");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { isLogin } = useSelector((s: RootState) => s.user);
  const [email, setEmail] = useState(null);

  const { t } = useTranslation();
  const onSubmit = async (values: any, { setErrors }) => {
    try {
      await Api.post("/register", values);
      setEmail(values.email);
      setPanelState("SUCCESS");
    } catch (e) {
      if (e.response?.data?.errors) {
        setErrors(parseErrorValidation(e.response?.data?.errors));
      } else {
        toast.error(
          e.response?.data?.message || "Something went wrong!, please try again"
        );
      }
    }
  };

  useEffect(() => {
    if (isLogin) {
      navigate("/");
    }
  }, [isLogin, navigate]);

  useEffect(() => {
    setLoading(true);
    window.location.href = "https://home.s.id/single/663580743512f289abfbecff";
  }, []);

  const resend = async () => {
    try {
      const response = await Api.post("/verify/email/resend", { email });
      console.log(response.status);
      if (response.status === 200) {
        // Resend successful, show success toast
        toast.success("Email verification has been sent.");
        return true;
      } else {
        // Resend failed with unexpected status code, show error toast
        toast.error("Failed to resend email verification. Please try again.");
        return false;
      }
    } catch (error) {
      // Handle specific error for status code 400
      if (error.response && error.response.status === 400) {
        // Response status code is 400, handle it
        const errorMessage = error.response.data.message || "Bad request.";
        toast.error(errorMessage);
      } else {
        // For other errors, show a generic error message
        toast.error("Something went wrong! Please try again.");
      }
      console.log(error); // Log the error for further investigation
    }
  };

  return (
    <div className="flex items-center justify-center h-screen">
      {loading ? (
        <BounceLoader color="#dc2626" loading={loading} size={150} />
      ) : (
        <Layout selected="" className="h-full flex">
          <Helmet title={t("Register")} />
          <div className="pt-20 md:pt-28 container flex flex-col justify-center flex-1 mx-auto font-sans">
            <div className="flex flex-col items-center w-full md:flex-row md:divide-x-2 md:w-auto md:py-10">
              <div className="md:pr-8 hidden md:block w-5/12">
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/register-illustration.jpg"
                  }
                  className="-mb-8 -mt-8 w-full"
                  alt="login"
                />
              </div>
              <div className="md:pl-8 pb-8 md:pb-0 md:w-7/12">
                <div className="p-2 w-full sm:w-full">
                  {panelState === "SUCCESS" && (
                    <div className="h-72 pt-10">
                      <h2 className="text-3xl">
                        {t("Registration Completed")}
                      </h2>
                      <p className="mt-2">
                        {t(
                          "Please Check your registered email for email verification"
                        )}
                      </p>
                      <p className="mt-8 italic">
                        <a
                          href="#resend"
                          className="text-red-600 underline"
                          onClick={resend}
                        >
                          {t("Click Here")}
                        </a>{" "}
                        <Trans i18nKey="to resend email verification" />
                      </p>
                    </div>
                  )}
                  {panelState === "IDLE" && (
                    <Formik
                      validationSchema={validation}
                      initialValues={{ ...initialValues }}
                      onSubmit={onSubmit}
                    >
                      {({ isSubmitting }) => (
                        <Form>
                          <FormLoading isLoading={isSubmitting}>
                            <div className="flex flex-wrap -mx-4">
                              <div className="p-2 w-full">
                                <label className="block mb-2 font-bold">
                                  {t("Full Name")}
                                </label>
                                <Field
                                  className="form-input w-full"
                                  name="name"
                                  type="text"
                                  placeholder={t("Full Name")}
                                />
                                <ErrorMessage name="name" />
                              </div>

                              <div className="p-2 w-full">
                                <label className="block mb-2 font-bold">
                                  {t("Email")}
                                </label>
                                <Field
                                  className="form-input w-full"
                                  name="email"
                                  type="email"
                                  placeholder={t("Email")}
                                />
                                <ErrorMessage name="email" />
                              </div>

                              <div className="p-2 w-full md:w-6/12">
                                <label className="block mb-2 font-bold">
                                  {t("Password")}
                                </label>
                                <Field
                                  className="form-input w-full"
                                  name="password"
                                  type="password"
                                  placeholder={t("Password")}
                                />
                                <ErrorMessage name="password" />
                              </div>

                              <div className="p-2 w-full md:w-6/12">
                                <label className="block mb-2 font-bold">
                                  {t("Repeat Password")}
                                </label>
                                <Field
                                  className="form-input w-full"
                                  name="password_confirmation"
                                  type="password"
                                  placeholder={t("Repeat Password")}
                                />
                                <ErrorMessage name="password_confirmation" />
                              </div>
                              <div className="p-2 w-full">
                                <label className="block mb-2 font-bold">
                                  {t("Age")}
                                </label>
                                <div className="w-28">
                                  <Field
                                    className="form-input"
                                    name="age"
                                    type="number"
                                    placeholder={t("Age")}
                                  />
                                </div>

                                <ErrorMessage name="age" />
                              </div>
                              <div className="p-2 w-full md:w-6/12">
                                <label className="block mb-2 font-bold">
                                  {t("Occupation")}
                                </label>
                                <Field
                                  className="form-input w-full"
                                  name="occupation"
                                  type="text"
                                  placeholder={t("Occupation")}
                                />
                                <ErrorMessage name="occupation" />
                              </div>

                              <div className="p-2 w-full md:w-6/12">
                                <label className="block mb-2 font-bold">
                                  {t("Company Name")}
                                </label>
                                <Field
                                  className="form-input w-full"
                                  name="company"
                                  type="text"
                                  placeholder={t("Company Name")}
                                />
                                <ErrorMessage name="company" />
                              </div>

                              <div className="p-2 w-full">
                                <label className="block mb-2 font-bold">
                                  {t("Phone Number")}
                                </label>
                                <div className="flex">
                                  <div className="py-2 pl-3 pr-4 bg-gray-200 -mr-1 border border-gray-300 rounded-l-md">
                                    +62
                                  </div>
                                  <Field
                                    className="form-input w-full"
                                    name="phone"
                                    type="text"
                                    placeholder="000 000 000"
                                  />
                                </div>

                                <ErrorMessage name="phone" />
                              </div>

                              <div className="p-2 w-full">
                                <label className="block mb-2 font-bold">
                                  {t("Full Address")}
                                </label>
                                <Field
                                  className="form-input w-full"
                                  name="address"
                                  component="textarea"
                                  placeholder={t("Full Address")}
                                />
                                <ErrorMessage name="address" />
                              </div>
                              <div className="p-2 w-full">
                                <Button
                                  type="submit"
                                  color="primary"
                                  className="font-bold w-full"
                                >
                                  {t("Register")}
                                </Button>
                              </div>
                            </div>

                            <div className="py-4 -mx-2">
                              {t("Already have an account?")}{" "}
                              <Link
                                to="/login"
                                className="text-red-600 hover:underline"
                              >
                                {t("Login")}
                              </Link>
                            </div>
                          </FormLoading>
                        </Form>
                      )}
                    </Formik>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Layout>
      )}
    </div>
  );
};

export default RegisterPage;
