import { Link, RouteComponentProps, useNavigate } from "@reach/router";
import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Button from "../Components/Button";
import ErrorMessage from "../Components/ErrorMessage";
import FormLoading from "../Components/FormLoading";
import Field from "../Components/InputField";
import Layout from "../Components/Layout";
import { RootState } from "../stores";
import Api, { parseErrorValidation } from "../utils/api";

interface Props extends RouteComponentProps {}

const ForgotPage = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isLogin } = useSelector((s: RootState) => s.user);

  const onSubmit = async (values, { setErrors }) => {
    try {
      await Api.post("/reset-password", values);
      toast.success(
        <>{t("Please check your email for resetting your new password")}</>
      );
    } catch (e) {
      if (e.response?.data?.errors) {
        setErrors(parseErrorValidation(e.response?.data?.errors));
      } else {
        toast.error(
          e.response?.data?.message ||
            t("Something went wrong!, please try again")
        );
      }
    }
  };

  useEffect(() => {
    if (isLogin) {
      navigate("/");
    }
  }, [isLogin, navigate]);

  return (
    <Layout selected="/forgot" className="h-full flex">
      <Helmet title={t("Login")} />
      <div className="pt-28 container flex items-center justify-center flex-1 mx-auto font-sans">
        <div className="flex flex-col md:flex-row md:divide-x-2 w-full md:w-auto">
          <div className="md:pr-8 hidden md:block">
            <img
              src={process.env.PUBLIC_URL + "/images/login-illustration.jpg"}
              className="-mb-8 -mt-8"
              alt="login"
            />
          </div>
          <div className="md:pl-8 pb-8 md:pb-0">
            <div className="p-4 sm:w-96">
              <Formik initialValues={{}} onSubmit={onSubmit}>
                {({ isSubmitting }) => (
                  <Form>
                    <FormLoading isLoading={isSubmitting}>
                      <div className="mb-4">
                        <label className="block mb-2 font-bold">
                          {t("Email Address")}
                        </label>
                        <Field
                          className="form-input w-full"
                          name="email"
                          type="email"
                          placeholder={t("Enter a valid email address")}
                          required
                        />
                        <ErrorMessage name="email" />
                      </div>

                      <div>
                        <Button
                          type="submit"
                          color="primary"
                          className="font-bold"
                        >
                          {t("Forgot Password")}
                        </Button>
                      </div>
                    </FormLoading>
                  </Form>
                )}
              </Formik>
            </div>
            <div className="p-4">
              {t(`Already have an account?`)}{" "}
              <Link to="/login" className="text-red-600 hover:underline">
                {t("Login")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ForgotPage;
