import { useNavigate } from "@reach/router";
import clsx from "clsx";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useSWR from "swr";
import { useWithLang } from "../i18n";
import ClockIcon from "../icons/ClockIcon";
import MicIcon from "../icons/MicIcon";
import ShareIcon from "../icons/ShareIcon";
import { RootState } from "../stores";
import { ApiResponse, getBaseURL } from "../utils/api";
import { RundownEvent } from "../utils/types";
import Button from "./Button";
import { Link } from "@reach/router";

interface Props {}

const EventItem = ({ data: item }: { data: RundownEvent }) => {
  const { t } = useTranslation();
  const { isLogin } = useSelector((state: RootState) => state.user);
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const navigate = useNavigate();

  // const joinMeeting = (item: RundownEvent) => {
  //   if (isLogin) {
  //     window.open(item.zoom, "_blank");
  //   } else {
  //     navigate("/login");
  //   }
  // };

  const downloadMaterial = (item: RundownEvent) => {
    if (isLogin) {
      window.open(item.presentation, "_blank");
    } else {
      navigate("/login");
    }
  };

  const toggleDetail = () => {
    if (isLogin) {
      setIsOpenDetail(!isOpenDetail);
    } else {
      navigate("/login");
    }
  };

  return (
    <li key={item.id} className="flex flex-col md:flex-row mb-8">
      <div className="flex-1 relative bg-white rounded-lg p-4 md:ml-6 text-sm md:text-base">
        <button className="absolute right-4 top-4 hidden">
          <ShareIcon className="w-8 transform duration-200 text-red-600 hover:text-red-900" />
        </button>
        <div className="flex items-center mb-2 text-gray-500">
          <div className="mr-3">
            <ClockIcon />
          </div>
          <time className="block">
            {item.hour_start.toString().padStart(2, "0")}:
            {item.minute_start.toString().padStart(2, "0")} -{" "}
            {item.hour_end.toString().padStart(2, "0")}:
            {item.minute_end.toString().padStart(2, "0")}
          </time>
        </div>
        <h2 className="text-lg md:text-xl text-red-600 font-bold font-sans text-center">
          {item.title}
        </h2>
        <div className="border-t border-gray-400 mt-3 md:ml-20 md:mr-20"></div>
        <div className="mt-2 leading-relaxed pt-3">
          <div className="mb-3 font-sans text-center">
            {item.description}
            {item.title === "Expo" && (
              <div>
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSfhLT2PfVzsB65sBaBwKDmz1bmviVPvOJLR86olOuxXfUU2Tw/viewform"
                  rel="noreferrer"
                  target="_blank"
                  className="text-red-600"
                >
                  {t("Register your business here")}
                </a>
              </div>
            )}
          </div>
          <div className="flex flex-wrap justify-center">
            {item.speakers.map((spk, index) => (
              <div
                key={index}
                className="p-4 flex flex-col items-center w-6/12 md:w-1/5"
              >
                <figure className="bg-gray-100  w-32 h-32 rounded-full overflow-hidden flex justify-center items-center">
                  <img src={getBaseURL(spk.image)} alt={spk?.name} />
                </figure>
                <div className="text-sm text-center mt-4">
                  <p className="font-semibold">
                    <MicIcon className="w-3 mr-2 hidden" />
                    {spk.name}
                  </p>
                  <p className="text-xs mt-2 text-gray-800">{spk.occupation}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="flex flex-wrap items-start justify-between mt-5">
            <Button color="secondary" size="sm" outline onClick={toggleDetail}>
              {isOpenDetail ? t("Close Detail") : t("See Detail")}
            </Button>
            <div className="w-full md:w-auto mt-3 md:mt-0">
              {isLogin && item.presentation && (
                <Button
                  color="secondary"
                  size="sm"
                  onClick={() => {
                    downloadMaterial(item);
                  }}
                  className="mr-3"
                  outline
                >
                  {t("Download Presentation")}
                </Button>
              )}
              {isLogin && item.title !== "Expo" && (
                <Link to="/register-event">
                  <Button color="primary" size="sm" outline>
                    {t("REGISTER EVENT")}
                  </Button>
                </Link>
              )}
            </div>
          </div>

          {isOpenDetail && (
            <div className="mt-2">
              <div>
                <dt className="font-bold">Zoom ID</dt>
                <dd>{item.zoom_id || "-"}</dd>
              </div>
              <div>
                <dt className="font-bold">Zoom Password</dt>
                <dd>{item.zoom_pw || "-"}</dd>
              </div>
            </div>
          )}
        </div>
      </div>
    </li>
  );
};

const EventRundown = (props: Props) => {
  const { isLogin } = useSelector((state: RootState) => state.user);
  const [selectedEvent, setSelectedEvent] = useState(0);
  const { f } = useWithLang();
  const { t } = useTranslation();
  const { data: d } = useSWR<
    ApiResponse<{ date: string; lists: RundownEvent[] }[]>
  >(() => f(isLogin ? "/users/events" : "/public/events"), {
    initialData: {
      data: [],
    } as any,
  });

  const onSelectEvent = (index) => {
    setSelectedEvent(index);
  };

  const eventLists = useMemo(() => {
    if (d.data.length === 0) {
      return [];
    }
    return d.data[selectedEvent].lists;
  }, [selectedEvent, d]);

  if (d?.data?.length === 0) {
    return <></>;
  }

  return (
    <div className="event-rundown mb-8 border border-gray-300 rounded-lg font-sans">
      <nav className="border-b-2 border-gray-600">
        <ul className="bg-gray-500 rounded-t-lg flex flex-nowrap overflow-x-auto">
          {d.data.map((item, index) => (
            <li
              key={index}
              className="text-center w-5/12 flex-shrink-0 md:flex-1"
            >
              <button
                className={clsx(
                  "appearance-none cursor-pointer p-3 w-full text-white",
                  "transform duration-200",
                  "hover:bg-black hover:bg-opacity-25",
                  { "bg-red-800": selectedEvent === index }
                )}
                onClick={() => {
                  onSelectEvent(index);
                }}
              >
                <h3 className="font-bold">
                  {t("DAY")} {index + 1}
                </h3>
                <time>{item.date}</time>
              </button>
            </li>
          ))}
        </ul>
      </nav>

      <div className="bg-gray-100 p-4 md:p-6 rounded-b-lg">
        {eventLists.length === 0 && (
          <h2 className="text-center text-3xl text-gray-800 mt-10 mb-20 font-sans">
            {t("No Schedule found")}
          </h2>
        )}
        <ul className="-mb-6">
          {eventLists.map((item) => (
            <EventItem data={item} key={item.id} />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default EventRundown;
