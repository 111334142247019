import clsx from "clsx";
import React from "react";

const USicon = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={clsx("w-5", className)}
      viewBox="0 0 513 342"
    >
      <path fill="#FFF" d="M0 0h513v342H0z" />
      <g fill="#D80027">
        <path d="M0 0h513v38H0zM0 76h513v38H0zM0 152h513v38H0zM0 228h513v38H0zM0 304h513v38H0z" />
      </g>
      <path fill="#2E52B2" d="M0 0h256.5v190H0z" />
      <g fill="#FFF">
        <path d="m47.8 141.9-4-12.8-4.3 12.8H26.3l10.7 7.7-4 12.8 10.8-7.9 10.7 7.9-4.2-12.8 10.9-7.7zM104.2 141.9l-4.1-12.8-4.2 12.8H82.7l10.7 7.7-4.1 12.8 10.8-7.9 10.7 7.9-4-12.8 10.8-7.7zM160.6 141.9l-4.2-12.8-4 12.8h-13.5l10.9 7.7-4.2 12.8 10.8-7.9 10.9 7.9-4.2-12.8 10.8-7.7zM216.9 141.9l-4.1-12.8-4.2 12.8h-13.2l10.7 7.7-4 12.8 10.7-7.9 10.8 7.9-4.2-12.8 10.9-7.7zM100.1 78.3l-4.2 12.8H82.7L93.4 99l-4.1 12.6 10.8-7.8 10.7 7.8-4-12.6 10.8-7.9h-13.4zM43.8 78.3l-4.3 12.8H26.3L37 99l-4 12.6 10.8-7.8 10.7 7.8L50.3 99l10.9-7.9H47.8zM156.4 78.3l-4 12.8h-13.5l10.9 7.9-4.2 12.6 10.8-7.8 10.9 7.8-4.2-12.6 10.8-7.9h-13.3zM212.8 78.3l-4.2 12.8h-13.2l10.7 7.9-4 12.6 10.7-7.8 10.8 7.8-4.2-12.6 10.9-7.9h-13.4zM43.8 27.7l-4.3 12.6H26.3L37 48.2l-4 12.7L43.8 53l10.7 7.9-4.2-12.7 10.9-7.9H47.8zM100.1 27.7l-4.2 12.6H82.7l10.7 7.9-4.1 12.7 10.8-7.9 10.7 7.9-4-12.7 10.8-7.9h-13.4zM156.4 27.7l-4 12.6h-13.5l10.9 7.9-4.2 12.7 10.8-7.9 10.9 7.9-4.2-12.7 10.8-7.9h-13.3zM212.8 27.7l-4.2 12.6h-13.2l10.7 7.9-4 12.7 10.7-7.9 10.8 7.9-4.2-12.7 10.9-7.9h-13.4z" />
      </g>
    </svg>
  );
};

export default USicon;
