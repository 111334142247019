import React from "react";

const Printicon = ({ className = "h-6 w-6" }) => {
  return (
    <svg
      className={className}
      width="40"
      height="32"
      viewBox="0 0 40 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="path-1-outside-1"
        maskUnits="userSpaceOnUse"
        x="7.25537"
        y="0.446838"
        width="26"
        height="14"
        fill="currentColor"
      >
        <rect fill="white" x="7.25537" y="0.446838" width="26" height="14" />
        <path d="M9.25537 12.0408V2.44684H30.4547V12.0408" />
      </mask>
      <path
        d="M7.25537 12.0408C7.25537 13.1454 8.1508 14.0408 9.25537 14.0408C10.3599 14.0408 11.2554 13.1454 11.2554 12.0408H7.25537ZM9.25537 2.44684V0.446838C8.1508 0.446838 7.25537 1.34227 7.25537 2.44684L9.25537 2.44684ZM30.4547 2.44684H32.4547C32.4547 1.34227 31.5593 0.446838 30.4547 0.446838V2.44684ZM28.4547 12.0408C28.4547 13.1454 29.3501 14.0408 30.4547 14.0408C31.5593 14.0408 32.4547 13.1454 32.4547 12.0408H28.4547ZM11.2554 12.0408V2.44684H7.25537V12.0408H11.2554ZM9.25537 4.44684H30.4547V0.446838H9.25537V4.44684ZM28.4547 2.44684V12.0408H32.4547V2.44684H28.4547Z"
        fill="currentColor"
        mask="url(#path-1-outside-1)"
      />
      <mask
        id="path-3-outside-2"
        maskUnits="userSpaceOnUse"
        x="0.188934"
        y="10.0408"
        width="40"
        height="17"
        fill="currentColor"
      >
        <rect fill="white" x="0.188934" y="10.0408" width="40" height="17" />
        <path d="M9.25537 24.376H5.72215C4.78508 24.376 3.8864 24.0872 3.22379 23.5731C2.56118 23.059 2.18893 22.3618 2.18893 21.6348V14.782C2.18893 14.055 2.56118 13.3578 3.22379 12.8437C3.8864 12.3296 4.78508 12.0408 5.72215 12.0408H33.9879C34.925 12.0408 35.8237 12.3296 36.4863 12.8437C37.1489 13.3578 37.5211 14.055 37.5211 14.782V21.6348C37.5211 22.3618 37.1489 23.059 36.4863 23.5731C35.8237 24.0872 34.925 24.376 33.9879 24.376H30.4547" />
      </mask>
      <path
        d="M9.25537 26.376C10.3599 26.376 11.2554 25.4805 11.2554 24.376C11.2554 23.2714 10.3599 22.376 9.25537 22.376V26.376ZM2.18893 21.6348H0.188934H2.18893ZM5.72215 12.0408V14.0408V12.0408ZM30.4547 22.376C29.3501 22.376 28.4547 23.2714 28.4547 24.376C28.4547 25.4805 29.3501 26.376 30.4547 26.376V22.376ZM9.25537 22.376H5.72215V26.376H9.25537V22.376ZM5.72215 22.376C5.17402 22.376 4.72052 22.203 4.44974 21.9929L1.99784 25.1533C3.05228 25.9714 4.39615 26.376 5.72215 26.376V22.376ZM4.44974 21.9929C4.19063 21.7919 4.18893 21.6438 4.18893 21.6348H0.188934C0.188934 23.0798 0.931735 24.3262 1.99784 25.1533L4.44974 21.9929ZM4.18893 21.6348V14.782H0.188934V21.6348H4.18893ZM4.18893 14.782C4.18893 14.773 4.19063 14.6249 4.44974 14.4239L1.99784 11.2635C0.931735 12.0906 0.188934 13.337 0.188934 14.782H4.18893ZM4.44974 14.4239C4.72052 14.2138 5.17402 14.0408 5.72215 14.0408V10.0408C4.39615 10.0408 3.05228 10.4454 1.99784 11.2635L4.44974 14.4239ZM5.72215 14.0408H33.9879V10.0408H5.72215V14.0408ZM33.9879 14.0408C34.536 14.0408 34.9895 14.2138 35.2603 14.4239L37.7122 11.2635C36.6578 10.4454 35.3139 10.0408 33.9879 10.0408V14.0408ZM35.2603 14.4239C35.5194 14.6249 35.5211 14.773 35.5211 14.782H39.5211C39.5211 13.337 38.7783 12.0906 37.7122 11.2635L35.2603 14.4239ZM35.5211 14.782V21.6348H39.5211V14.782H35.5211ZM35.5211 21.6348C35.5211 21.6438 35.5194 21.7919 35.2603 21.9929L37.7122 25.1533C38.7783 24.3262 39.5211 23.0798 39.5211 21.6348H35.5211ZM35.2603 21.9929C34.9895 22.203 34.536 22.376 33.9879 22.376V26.376C35.3139 26.376 36.6578 25.9714 37.7122 25.1533L35.2603 21.9929ZM33.9879 22.376H30.4547V26.376H33.9879V22.376Z"
        fill="currentColor"
        mask="url(#path-3-outside-2)"
      />
      <path
        d="M31.4547 18.8937C31.4547 18.3414 31.007 17.8937 30.4547 17.8937H9.25537C8.70309 17.8937 8.25537 18.3414 8.25537 18.8937V29.8582C8.25537 30.4105 8.70309 30.8582 9.25537 30.8582H30.4547C31.007 30.8582 31.4547 30.4105 31.4547 29.8582V18.8937Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Printicon;
