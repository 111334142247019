import React from "react";

const ShareIcon = ({ className = "h-6 w-6" }) => {
  return (
    <svg
      viewBox="0 0 35 35"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.5 17.5C33.5 26.3366 26.3366 33.5 17.5 33.5C8.66344 33.5 1.5 26.3366 1.5 17.5C1.5 8.66344 8.66344 1.5 17.5 1.5C26.3366 1.5 33.5 8.66344 33.5 17.5Z"
        stroke="currentColor"
        strokeWidth="3"
      />
      <path
        d="M21 14C22.6569 14 24 12.6569 24 11C24 9.34315 22.6569 8 21 8C19.3431 8 18 9.34315 18 11C18 12.6569 19.3431 14 21 14Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 21C10.6569 21 12 19.6569 12 18C12 16.3431 10.6569 15 9 15C7.34315 15 6 16.3431 6 18C6 19.6569 7.34315 21 9 21Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 28C22.6569 28 24 26.6569 24 25C24 23.3431 22.6569 22 21 22C19.3431 22 18 23.3431 18 25C18 26.6569 19.3431 28 21 28Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5898 19.51L18.4198 23.49"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.4098 12.51L11.5898 16.49"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ShareIcon;
