import {
  Link,
  RouteComponentProps,
  useLocation,
  useNavigate,
} from "@reach/router";
import { Form, Formik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import Helmet from "react-helmet";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import Button from "../Components/Button";
import ErrorMessage from "../Components/ErrorMessage";
import FormLoading from "../Components/FormLoading";
import Field from "../Components/InputField";
import Layout from "../Components/Layout";
import { RootState } from "../stores";
import Api, { parseErrorValidation } from "../utils/api";

interface Props extends RouteComponentProps {}

const validation = Yup.object().shape({
  password: Yup.string().required().min(8),
  password_confirmation: Yup.string()
    .required()
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

const ResetPasswordPage = (props: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { isLogin } = useSelector((s: RootState) => s.user);
  const [status, setStatus] = useState<"PENDING" | "SUCCESS">("PENDING");

  const token = useMemo(() => {
    const query = new URLSearchParams(location.search);
    return query.get("token");
  }, [location.search]);

  const onSubmit = async (values, { setErrors }) => {
    try {
      await Api.post("/update-password", { ...values, token });
      setStatus("SUCCESS");
    } catch (e) {
      if (e.response?.data?.errors) {
        setErrors(parseErrorValidation(e.response?.data?.errors));
      } else {
        toast.error(
          e.response?.data?.message ||
            "Something went wrong!, please try again."
        );
      }
    }
  };

  useEffect(() => {
    if (isLogin || !token) {
      navigate("/");
    }
  }, [isLogin, navigate, token]);

  return (
    <Layout selected="/reset-password" className="h-full flex">
      <Helmet title={t("Reset Password")} />
      <div className="pt-28 container flex items-center justify-center flex-1 mx-auto font-sans">
        <div className="flex flex-col md:flex-row md:divide-x-2 w-full md:w-auto">
          <div className="md:pr-8 hidden md:block">
            <img
              src={process.env.PUBLIC_URL + "/images/login-illustration.jpg"}
              className="-mb-8 -mt-8"
              alt="login"
            />
          </div>
          <div className="md:pl-8 pb-8 md:pb-0">
            {status === "PENDING" && (
              <>
                <div className="p-4 sm:w-96">
                  <Formik
                    validationSchema={validation}
                    initialValues={{ password: "", password_confirmation: "" }}
                    onSubmit={onSubmit}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <FormLoading isLoading={isSubmitting}>
                          <div className="mb-4">
                            <label className="block mb-2 font-bold">
                              {t("New Password")}
                            </label>
                            <Field
                              className="form-input w-full"
                              name="password"
                              type="password"
                              placeholder={t("Enter new password")}
                              required
                            />
                            <ErrorMessage name="password" />
                          </div>

                          <div className="mb-4">
                            <label className="block mb-2 font-bold">
                              {t("Repeat Password")}
                            </label>
                            <Field
                              className="form-input w-full"
                              name="password_confirmation"
                              type="password"
                              placeholder={t("Enter new password")}
                              required
                            />
                            <ErrorMessage name="password_confirmation" />
                          </div>

                          <div>
                            <Button
                              type="submit"
                              color="primary"
                              className="font-bold"
                            >
                              {t("Create new password")}
                            </Button>
                          </div>
                        </FormLoading>
                      </Form>
                    )}
                  </Formik>
                </div>
                <div className="p-4">
                  {t(`Already have an account?`)}{" "}
                  <Link to="/login" className="text-red-600 hover:underline">
                    {t("Login")}
                  </Link>
                </div>
              </>
            )}

            {status === "SUCCESS" && (
              <>
                <h2 className="text-2xl">{t("Congratulations!")}</h2>
                <p className="mt-2">Your password has been changed!</p>
                <p className="mt-2">
                  <Link className="text-red-600 underline" to="/login">
                    {t("Click Here")}
                  </Link>{" "}
                  {t("To Login with your new password")}
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ResetPasswordPage;
